import {
  type RouteDefinition,
  createAsync,
  query,
  useParams,
} from "@solidjs/router";

import { gql } from "~/__gql-generated__";
import { PostRevisionStatus } from "~/__gql-generated__/graphql";
import PostList from "~/components/PostList";
import Title from "~/components/Title";
import styles from "~/routes/tag/[tag].module.scss";
import { client } from "~/utils/graphql";

const TAG = gql(`
  query Tag($slug: String!) {
    tags(where: { slug: $slug }) {
      title
    }
  }
`);

const getTag = query(async (slug: string) => {
  "use server";

  const { data } = await client.query({ query: TAG, variables: { slug } });

  return data.tags[0];
}, "tag");

export const route = {
  preload: ({ location }) => {
    const pieces = location.pathname.split("/");
    return getTag(pieces[2]);
  },
} satisfies RouteDefinition;

export default function Tag() {
  const parameters = useParams();
  const tag = createAsync(() => getTag(parameters.tag));

  return (
    <PostList
      filter={{
        revisionsConnection_SOME: {
          edge: { status: PostRevisionStatus.Current },
          node: { tags_SOME: { slug: parameters.tag } },
        },
      }}
    >
      <Title>{`Artigos com a tag #${tag()?.title ?? ""}`}</Title>
      <h1 class={styles.title}>
        Artigos com a <i lang="en">tag</i> #<b>{tag()?.title}</b>
      </h1>
    </PostList>
  );
}
